/*
Copyright 2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_MemberListHeaderView {
    border-bottom: var(--cpd-border-width-1) solid var(--cpd-color-gray-400);
    max-height: 112px;

    .mx_MemberListHeaderView_container {
        margin-top: var(--cpd-space-6x);
        width: 100%;
    }

    .mx_MemberListHeaderView_invite_small {
        margin-left: var(--cpd-space-3x);
    }

    .mx_MemberListHeaderView_invite_large {
        width: 288px;
        height: 36px;
    }

    .mx_MemberListHeaderView_label {
        padding: var(--cpd-space-6x) 0 var(--cpd-space-2x) var(--cpd-space-4x);
        box-sizing: border-box;
        width: 100%;
        color: var(--cpd-color-text-secondary);
        font: var(--cpd-font-body-sm-semibold);
    }

    .mx_MemberListHeaderView_search {
        width: 240px;
    }
}
